var timeouts = [];

$(document).on("turbolinks:before-cache", function () {
    clearCircleSetTimeouts();
});

$(document).on("turbolinks:load", function () {
    var TextType = function (el, wordsArray, period, specialAnimation) {
        this.wordsArray = wordsArray;
        this.specialAnimation = specialAnimation;
        this.el = el;
        this.loopNum = 0;
        this.period = parseInt(period, 10) || 2000;
        this.text = '';
        this.tick();
        this.isDeleting = false;
    };

    startHeaderAnimation();

    $('.js-btn-scroll-to').click(function () {
        let scrollTo = $('.js-scroll-to');
        let navHeight = $('.nav-row').innerHeight();
        if (scrollTo.length > 0) {
            $("html, body").animate({scrollTop: $(scrollTo[0]).offset().top - navHeight}, 500);
        }
    });

    TextType.prototype.tick = function () {
        let animatedBlocks = $('.js-animated-block');
        let index = this.loopNum % this.wordsArray.length;
        let fullText = this.wordsArray[index];
        let specialAnimation = this.specialAnimation[index];

        if (this.isDeleting) {
            this.text = fullText.substring(0, this.text.length - 1);
            $(animatedBlocks[index]).removeClass('show-animation');
            timeouts.push(setTimeout(function () {
                $(animatedBlocks[index]).removeClass('after-show-animation');
                stopSpecialAnimation(specialAnimation);
            }, 200));
        } else {
            this.text = fullText.substring(0, this.text.length + 1);
            $(animatedBlocks[index]).addClass('show-animation').addClass('after-show-animation');
            startSpecialAnimation(specialAnimation);
        }

        this.el.innerHTML = '<span class="wrap">' + this.text + '</span>';

        let delta = 200 - Math.random() * 100;

        if (this.isDeleting) {
            delta /= 2;
        }

        if (!this.isDeleting && this.text === fullText) {
            delta = this.period;
            this.isDeleting = true;
        } else if (this.isDeleting && this.text === '') {
            this.isDeleting = false;
            this.loopNum++;
            delta = 500;
        }

        let _this = this;

        timeouts.push(setTimeout(function () {
            _this.tick();
        }, delta));
    };

    function startHeaderAnimation() {
        let startDelay = 1000;
        let period = 3000;

        timeouts.push(setTimeout(function () {
            $('.js-animated-block').first().addClass('show-animation').addClass('after-show-animation');
            let elements = $('.js-typing-animation');
            for (let i = 0; i < elements.length; i++) {
                let wordsArray = $(elements[i]).attr('data-type');
                let specialAnimation = $(elements[i]).attr('data-special-animation');
                if (wordsArray) {
                    new TextType(elements[i], JSON.parse(wordsArray), period, JSON.parse(specialAnimation));
                }
            }
        }, startDelay));
    }

    function startSpecialAnimation(animationName) {
        if (animationName === 'diagram') {
            timeouts.push(setTimeout(function () {
                let canvas = $('.js-diagram-canvas');
                if (canvas.attr('data-status') !== 'stared') {
                    canvas.attr('data-status', 'stared');
                    drawCircleDiagram(canvas[0], 65, 500);
                }
            }, 500));
        }
    }

    function stopSpecialAnimation(animationName) {
        if (animationName === 'diagram') {
            let canvas = $('.js-diagram-canvas');
            if (canvas.attr('data-status') !== 'cleared') {
                canvas.attr('data-status', 'cleared');
                let ctx = canvas[0].getContext('2d');
                ctx.clearRect(0, 0, 112, 112);
            }
        }
    }

    function drawCircleDiagram(canvas, percentages, duration) {
        let colors = ['#F50045', 'rgba(0, 0, 0, 0)'];
        let ctx = canvas.getContext('2d');
        let stepTime = Math.ceil(duration / percentages);

        let iterator = 0;
        timeouts.push(setTimeout(function tick() {
            let datalist = [iterator, 100 - iterator];
            drawCircle(ctx, canvas.width, canvas.height, 28, datalist, 0);
            if (iterator < percentages) {
                timeouts.push(setTimeout(tick, stepTime));
            } else {
                drawCircle(ctx, canvas.width, canvas.height, 28, datalist, 20);
            }
            iterator++;
        }, stepTime));

        function drawCircle(ctx, w, h, radius, datalist, shadowBlur) {
            let centerX = w / 2;
            let centerY = h / 2;
            let total = 0;
            for (let i = 0; i < datalist.length; i++) {
                total += datalist[i];
            }

            let lastEnd = 0;
            let offset = Math.PI / 2;
            for (let i = 0; i < datalist.length; i++) {
                let thisPart = datalist[i];
                ctx.beginPath();
                ctx.fillStyle = colors[i];
                ctx.moveTo(centerX, centerY);
                let arcSector = Math.PI * (2 * thisPart / total);
                ctx.shadowBlur = shadowBlur;
                ctx.shadowColor = 'rgba(245, 0, 69, 0.4)';
                ctx.arc(centerX, centerY, radius, lastEnd - offset, lastEnd + arcSector - offset, false);
                ctx.lineTo(centerX, centerY);
                ctx.fill();
                ctx.closePath();

                lastEnd += arcSector;
            }
        }
    }
});

function clearCircleSetTimeouts() {
    for (let i = 0; i < timeouts.length; i++) {
        clearTimeout(timeouts[i]);
    }
    timeouts = [];
}
