$(document).on("turbolinks:load", function () {
    emailCompiler();

    $('.js-checkbox-agree').on('change', function () {
        if (this.checked) {
            $('.js-terms-arrow').addClass('hidden');
        }
    });

    $('.js-close-thank-you').on('click', function () {
        let form = $('.js-contact-form');
        form.find('.form-input, .form-textarea, .file-loader').val('');
        form.find('.js-checkbox-agree').prop('checked', false);
        form.find('.load-files').html('');
        form.find('.device-errors').html('');
        $('.js-thank-you-block').removeClass('show-animation');
    });

    $('.js-btn-submit').on('click', function () {
        if ($('.js-checkbox-agree').is(':checked')) {
            $('.js-input-submit').click();
        } else {
            $('.js-terms-arrow').removeClass('hidden');
            let termsField = $('.js-terms-field');
            termsField.addClass('zoomAnimation');
            setTimeout(function () {
                termsField.removeClass('zoomAnimation');
            }, 1000);
        }
    });

    $('.js-contact-form').validate({
        rules: {
            'name': {
                required: true
            },
            'email': {
                required: true,
                email: true
            }
        }
    });

    jQuery.extend(jQuery.validator.messages, {
        required: 'Must be filled'
    });

    function emailCompiler() {
        let emailsData = {
            clients: {
                u: 'connect',
                d: 'everlabs.com'
            },
            workers: {
                u: 'jobs',
                d: 'everlabs.com'
            }
        };

        $('[data-mail]').each(function (i, el) {
            let prop = $(el).attr('data-mail');
            let data = emailsData[prop].u + '@' + emailsData[prop].d;
            $(el).html(data);
        });
    }
});
