$(document).on("turbolinks:load", function () {
    initProjectsAnimation();

    $('.js-add-projects').on('click', function () {
        initProjectsAnimation();
    });

    function initProjectsAnimation() {
        let projectCards = $('.js-project-card');
        projectCards.on('mouseover', function () {
            $(this).find('.fadeInUpBlock').addClass('fadeInUp');
        });

        projectCards.on('mouseout', function () {
            $(this).find('.fadeInUpBlock').removeClass('fadeInUp');
        });
    }
});
