import Cookies from 'js.cookie';

$(document).on("turbolinks:load", function () {
    let likeBtn = $('.js-like-button');
    if (isCookiesOn()) {
        let articleID = likeBtn.attr('data-id');
        let name = '_' + articleID + '_like_status';
        if (Cookies.get(name) === 'liked') {
            likeBtn.addClass('active');
        }
    }

    likeBtn.on('click', function () {
        if (!isCookiesOn()) {
            $('.js-cookies-disabled-notification').addClass('transition').addClass('show');
            return false;
        } else if ($(this).hasClass('active')) {
            return false;
        } else {
            $(this).addClass('active');
            let articleID = $(this).attr('data-id');
            let name = '_' + articleID + '_like_status';
            let oneDay = new Date();
            oneDay.setDate(oneDay.getDate() + 1);
            Cookies.set(name, 'liked', {expires: oneDay});

            let likesNumber = $('.js-likes-number');
            likesNumber.html(parseInt(likesNumber.html()) + 1);
        }
    });

    function isCookiesOn() {
        Cookies.set('_test_cookie', 'test');
        return Cookies.get('_test_cookie') !== null;
    }
});
