$(document).on("turbolinks:load", function () {
    $('.js-contact-anchor').on('click', function () {
        let textBlocks = $('.js-changeable-text');
        for (let i = 0; i < textBlocks.length; i++) {
            $(textBlocks[i]).html($(textBlocks[i]).attr('data-text'));
        }

        let navHeight = $('.nav-row').innerHeight();
        $("html, body").animate({scrollTop: $('.js-contact-section').offset().top - navHeight + 1}, 500);
    });
});
