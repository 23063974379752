$(document).on("turbolinks:load", function () {
    let anchors = $('.js-anchor');

    navSettings();
    setActiveLink();
    window.addEventListener('scroll', navSettings);
    window.addEventListener('resize', navSettings);

    anchors.on('click', function () {
        let anchor = $(this).attr('data-anchor');
        let isMobile = $(this).parents('.js-mobile-nav').length > 0;
        if (isMobile) {
            closeMenu();
        }

        if (anchor !== undefined) {
            let element = $(anchor);
            if (element.length > 0) {
                let navHeight = $('.nav-row').innerHeight();
                $("html, body").animate({scrollTop: element.offset().top - navHeight + 1}, 500);
            } else {
                location.href = $(this).attr('data-url');
            }
        }
    });

    $('.js-menu-btn').on('click', function () {
        if ($(this).hasClass('is-active')) {
            closeMenu();
        } else {
            openMenu();
        }
    });

    function setActiveLink() {
        let location = document.location.href.split('/')[3];
        let navBarLinks = $('.nav-bar .js-nav-item');
        for (let i = 0; i < navBarLinks.length; i++) {
            let linkHref = $(navBarLinks[i]).attr('href').split('/')[1];
            if (location === linkHref) {
                $(navBarLinks[i]).addClass('active');
            }
        }
    }

    function closeMenu() {
        $('.js-menu-btn').removeClass('is-active');
        $('.js-mobile-nav').removeClass('show-menu');
        $('body').removeClass('overflow-hidden');
    }

    function openMenu() {
        $('.js-menu-btn').addClass('is-active');
        $('.js-mobile-nav').addClass('show-menu');
        $('body').addClass('overflow-hidden');
    }

    function navSettings() {
        let sections = $('.anchor-section');
        let navBarHeight = $('.nav-row').innerHeight();
        let activeAnchor = '';

        for (let i = 0; i < sections.length; i++) {
            let element = sections[i];
            let elementTopPosition = -element.getBoundingClientRect().top;
            if (elementTopPosition + navBarHeight >= 0) {
                activeAnchor = $(element).attr('id');
            }
        }
        anchors.removeClass('active');
        $('.js-anchor[data-anchor="#' + activeAnchor + '"]').addClass('active');

        if ($('.js-menu-btn').hasClass('is-active') && window.innerWidth >= 992) {
            closeMenu();
        }
    }
});
