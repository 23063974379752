// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("jquery");
require("jquery-validation");
require("bootstrap/dist/js/bootstrap");

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

import 'stylesheets/application';
import 'javascripts/form';
import 'javascripts/menu';
import 'javascripts/os-style-fixes';
import 'javascripts/contact-form';
import 'javascripts/header-section';
import 'javascripts/fixed-block';
import 'javascripts/technologies-section';
import 'javascripts/projects-section';
import 'javascripts/reviews-section';
import 'javascripts/news-header';
import 'javascripts/team-section';
import 'javascripts/like-section';
import 'javascripts/numbers-section';
import 'javascripts/cookies-notification';
import 'javascripts/ie-notification';
import 'javascripts/tabs-section';
import 'javascripts/error-page';
import 'javascripts/jobs-section';
import 'javascripts/editor';

import 'javascripts/slick';
