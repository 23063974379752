$(document).on("turbolinks:load", function () {
    $('.js-tab').on('click', function () {
        if (!$(this).hasClass('active')) {
            $(this).parent().find('.js-tab').removeClass('active');
            $(this).addClass('active');

            let id = $(this).attr('data-id');
            $('.js-tab-content').addClass('hidden');
            $('.js-tab-content[data-id="' + id + '"]').removeClass('hidden');
        }
    });
});
