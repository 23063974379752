$(document).on("turbolinks:load", function () {
    setTimeout(function () {
        headerSettings();
        window.addEventListener('scroll', headerSettings);
        window.addEventListener('resize', headerSettings);
    }, 1000);

    function headerSettings() {
        let navHeight = $('.nav-row').innerHeight();
        let elements = $('.js-news-header');
        for (let i = 0; i < elements.length; i++) {
            let topPosition = elements[i].getBoundingClientRect().top;
            let elementHeight = $(elements[i]).innerHeight();
            if (topPosition - navHeight < -elementHeight / 3) {
                $(elements[i]).addClass('show-animation');
            } else {
                $(elements[i]).removeClass('show-animation');
            }
        }
    }
});
