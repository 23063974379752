$(document).on("turbolinks:load", function () {
    editorSettings();

    function editorSettings() {
        if (!isIE() && $('.js-news-content-section').length > 0) {
            import('highlight.js/styles/railscasts');
            import('highlight.js')
                .then(hljs => codeStyleSettings(hljs))
                .catch(error => {
                    console.error(error);
                });
        }
    }

    function codeStyleSettings(hljs) {
        let codeBlocks = $('.editor-styles pre');
        for (let i = 0; i < codeBlocks.length; i++) {
            hljs.highlightBlock(codeBlocks[i]);
        }
    }

    function isIE() {
        return (window.navigator.userAgent.indexOf("MSIE ") > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./));
    }
});
