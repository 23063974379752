$(document).on("turbolinks:load", function () {
    if ($('.error-page').length > 0) {
        $('.footer').addClass('hidden');
        $('.nav-bar').addClass('opacity-nav');
        let socialLinks = $('.social-links');
        socialLinks.find('.facebook-black').removeClass('.facebook-black').addClass('facebook-white');
        socialLinks.find('.instagram-black').removeClass('.instagram-black').addClass('instagram-white');
        socialLinks.find('.linkedin-black').removeClass('.linkedin-black').addClass('linkedin-white');
        socialLinks.find('.youtube-black').removeClass('.youtube-black').addClass('youtube-white');
    }
});
