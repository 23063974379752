$(document).on("turbolinks:load", function () {
    let notification = $('.js-ie-notification');
    if (isIE()) {
        notification.addClass('transition').addClass('show');
    }

    $('.js-close-ie-notification').on('click', function () {
        notification.removeClass('show');
    });

    function isIE() {
        return (window.navigator.userAgent.indexOf("MSIE ") > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./));
    }
});
