$(document).on("turbolinks:load", function () {
    fixedBlockSettings();
    window.addEventListener('scroll', fixedBlockSettings);
    window.addEventListener('resize', fixedBlockSettings);

    function fixedBlockSettings() {
        let element = $('.js-fixed-block').first();
        if (element.length > 0) {
            let width = parseInt(element.attr('data-width'));
            let navHeight = $('.nav-row').innerHeight();
            let elementHeight = element[0].clientHeight;
            let parentElement = element.parents('.js-parent-block')[0];
            let parentHeight = parentElement.clientHeight;
            let parentTopPosition = -parentElement.getBoundingClientRect().top;

            if (parentTopPosition >= -navHeight && window.innerWidth >= width) {
                if (parentTopPosition > parentHeight - elementHeight - navHeight) {
                    element.removeClass('fixed').addClass('absolute');
                } else {
                    element.addClass('fixed').removeClass('absolute');
                }
            } else {
                element.removeClass('fixed').removeClass('absolute');
            }
        }
    }
});
