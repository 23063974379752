$(document).on('turbolinks:before-cache', function () {
    $('.js-reviews').slick('unslick');
});

$(document).on("turbolinks:load", function () {
    $('.js-reviews').slick({
        dots: true,
        slidesToShow: 2,
        prevArrow: "" +
            "<button type='button' class='slick-prev slick-arrow animated-arrow'>" +
            "   <div class='arrows-container'>" +
            "       <div class='arrow-block arrow-left-2 arrow-bold-left-red'></div>" +
            "       <div class='arrow-block arrow-left-1 arrow-left-red'></div>" +
            "   </div>" +
            "</button>",
        nextArrow: "" +
            "<button type='button' class='slick-next slick-arrow animated-arrow'>" +
            "   <div class='arrows-container'>" +
            "       <div class='arrow-block arrow-right-2 arrow-bold-right-red'></div>" +
            "       <div class='arrow-block arrow-right-1 arrow-right-red'></div>" +
            "   </div>" +
            "</button>",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
});
