$(document).on('turbolinks:before-cache', function () {
    $('.js-technologies').slick('unslick');
});

$(document).on("turbolinks:load", function () {
    $('.js-technologies').slick({
        dots: false,
        infinite: true,
        slidesToShow: 11,
        centerMode: false,
        variableWidth: true,
        autoplay: true,
        autoplaySpeed: 4000,
        prevArrow: "" +
            "<button type='button' class='slick-prev slick-arrow animated-arrow'>" +
            "   <div class='arrows-container'>" +
            "       <div class='arrow-block arrow-left-2 arrow-bold-left-red'></div>" +
            "       <div class='arrow-block arrow-left-1 arrow-left-red'></div>" +
            "   </div>" +
            "</button>",
        nextArrow: "" +
            "<button type='button' class='slick-next slick-arrow animated-arrow'>" +
            "   <div class='arrows-container'>" +
            "       <div class='arrow-block arrow-right-2 arrow-bold-right-red'></div>" +
            "       <div class='arrow-block arrow-right-1 arrow-right-red'></div>" +
            "   </div>" +
            "</button>",
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    centerMode: false,
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    centerMode: true,
                    slidesToShow: 1
                }
            }
        ]
    });
});
