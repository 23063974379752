$(document).on("turbolinks:load", function () {
    let os = [
        {name: 'Windows Phone', value: 'Windows Phone', version: 'OS'},
        {name: 'Windows', value: 'Win', version: 'NT'},
        {name: 'iPhone', value: 'iPhone', version: 'OS'},
        {name: 'iPad', value: 'iPad', version: 'OS'},
        {name: 'Kindle', value: 'Silk', version: 'Silk'},
        {name: 'Android', value: 'Android', version: 'Android'},
        {name: 'PlayBook', value: 'PlayBook', version: 'OS'},
        {name: 'BlackBerry', value: 'BlackBerry', version: '/'},
        {name: 'Macintosh', value: 'Mac', version: 'OS X'},
        {name: 'Linux', value: 'Linux', version: 'rv'},
        {name: 'Palm', value: 'Palm', version: 'PalmOS'}
    ];

    let header = [
        navigator.platform,
        navigator.userAgent,
        navigator.appVersion,
        navigator.vendor,
        window.opera
    ];

    let agent = header.join(' ');
    let osName = matchItem(agent, os).name;
    if (osName === 'Windows') {
        $('.js-nav-row').addClass('os-style-fix');
    }

    function matchItem(string, data) {
        for (let i = 0; i < data.length; i++) {
            let regex = new RegExp(data[i].value, 'i');
            let match = regex.test(string);
            if (match) {
                let regexv = new RegExp(data[i].version + '[- /:;]([\d._]+)', 'i');
                let matches = string.match(regexv);
                let version = '';
                if (matches) {
                    if (matches[1]) {
                        matches = matches[1];
                    }
                }
                if (matches) {
                    matches = matches.split(/[._]+/);
                    for (let j = 0; j < matches.length; j++) {
                        if (j === 0) {
                            version += matches[j] + '.';
                        } else {
                            version += matches[j];
                        }
                    }
                } else {
                    version = '0';
                }
                return {
                    name: data[i].name,
                    version: parseFloat(version)
                };
            }
        }
        return {name: 'unknown', version: 0};
    }
});
