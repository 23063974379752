import Cookies from 'js.cookie';

$(document).on("turbolinks:load", function () {
    let cookiesNotification = $('.js-cookies-notification');
    let cookiesDisabledNotification = $('.js-cookies-disabled-notification');

    if (!isCookiesOn()) {
        cookiesDisabledNotification.addClass('transition').addClass('show');
    } else if (Cookies.get('_everlabs_cookies_agreement') == undefined) {
        cookiesNotification.addClass('transition').addClass('show');
    }

    $('.js-agree-cookies').on('click', function () {
        let oneYear = new Date();
        oneYear.setDate(oneYear.getDate() + 365);
        Cookies.set('_everlabs_cookies_agreement', 'agree', {expires: oneYear});
        cookiesNotification.removeClass('show');
    });

    $('.js-disagree-cookies').on('click', function () {
        let oneDay = new Date();
        oneDay.setDate(oneDay.getDate() + 1);
        Cookies.set('_everlabs_cookies_agreement', 'disagree', {expires: oneDay});
        cookiesNotification.removeClass('show');
    });

    $('.js-close-cookies-notification').on('click', function () {
        cookiesDisabledNotification.removeClass('show');
    });

    function isCookiesOn() {
        Cookies.set('_test_cookie', 'test');
        return Cookies.get('_test_cookie') !== null;
    }
});
