$(document).on("turbolinks:load", function () {
    fileLoaderSettings();

    function fileLoaderSettings() {
        let inputs = document.querySelectorAll('.file-loader');
        Array.prototype.forEach.call(inputs, function (input) {
            let id = $(input).attr('id');
            let label = $('label[for="' + id + '"]');
            let labelVal = '';
            if (label !== null) {
                labelVal = label.innerHTML;
            }

            $(input).on('change', function (e) {
                let files = this.files;
                let fileName = '';

                if (files && files.length > 1) {
                    let names = [];
                    for (let i = 0; i < files.length; i++) {
                        names.push(files[i].name);
                    }
                    fileName = names.join(', ');
                } else {
                    fileName = e.target.value.split('\\').pop();
                }

                if (fileName) {
                    let html = '<div class="text">' + fileName + '</div><div class="close-icon image-contain close-gray js-remove-files"></div>';
                    $(label).addClass('with-file');
                    $(label).parents('.file-loader-block').find('.load-files').html(html);

                    $('.js-remove-files').on('click', function () {
                        let parent = $(this).parents('.file-loader-block');
                        parent.find('.file-loader').val('');
                        parent.find('.load-files').html('');

                        let form = parent.parents('form');
                        form.find('.device-errors').html('');
                    });
                } else {
                    $(label).html(labelVal);
                }
            });
        });
    }
});
