$(document).on("turbolinks:load", function () {
    setTimeout(function () {
        counterSettings();
        window.addEventListener('scroll', counterSettings);
        window.addEventListener('resize', counterSettings);
    }, 1000);

    function counterSettings() {
        let navHeight = $('.nav-row').innerHeight();
        let elements = $('.js-count-animation');
        for (let i = 0; i < elements.length; i++) {
            let topPosition = elements[i].getBoundingClientRect().top;
            let element = $(elements[i]);

            if (!element.hasClass('animation-done') && window.innerHeight > topPosition + navHeight && topPosition > navHeight) {
                element.addClass('animation-done');
                element.prop('Counter', 0).animate({
                    Counter: element.attr('data-value')
                }, {
                    duration: 3000,
                    easing: 'swing',
                    step: function (now) {
                        let number = Math.ceil(now);
                        element.text(numberFormat(number));
                    }
                });
            }
        }
    }

    function numberFormat(number) {
        let strNumber = number.toString();
        if (number >= 1000) {
            let reverseNumber = reverseString(strNumber);
            let str = [reverseNumber.slice(0, 3), ' ', reverseNumber.slice(3)].join('');
            strNumber = reverseString(str);
        }
        return strNumber;
    }

    function reverseString(str) {
        let splitString = str.split("");
        let reverseArray = splitString.reverse();
        return reverseArray.join("");
    }
});
